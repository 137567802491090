$env-production: true;
@import '~@styles/config/variables';

.form {
  padding: 20px 15px;
  border-radius: 4px;
  background-color: $siren;

  &-values {
    margin-top: 8px;
    padding: 20px 15px;
    border-radius: 4px;
    background-color: rgba($white, 0.4);
  }
}
