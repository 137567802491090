$env-production: true;
@import '~@styles/config/variables';
@import '~@styles/helpers/functions';
@import '~@styles/helpers/mixins';
@import '~@styles/base/placeholders';

.not-displayable {
  $transition-duration: 250ms;

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  opacity: 0;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  outline-style: solid;
  transition: opacity $transition-duration ease-out 0ms;
  position: absolute;
  z-index: 0;
  background-color: $light-black;

  @include breakpoint(orientation portrait) {
    @include breakpoint(map-get($breakpoints, x-small)) {
      outline-style: none;

      @include breakpoint(max-height 360px) {
        outline-style: solid;
      }
    }

    @include breakpoint(map-get($breakpoints, small)) {
      outline-style: none;
    }
  }

  @include breakpoint(orientation landscape) {
    outline-style: solid;

    @include breakpoint(map-get($breakpoints, x-small)) {
      outline-style: solid;
    }

    @include breakpoint(map-get($breakpoints, small)) {
      outline-style: none;

      @include breakpoint(max-height 240px) {
        outline-style: solid;
      }

      @include breakpoint((max-height 350px) (max-width (560px))) {
        outline-style: solid;
      }
    }

    @include breakpoint(map-get($breakpoints, medium)) {
      outline-style: none;

      @include breakpoint(max-height 340px) {
        outline-style: solid;
      }
    }

    @include breakpoint(map-get($breakpoints, large)) {
      outline-style: none;

      @include breakpoint(max-height 420px) {
        outline-style: solid;
      }
    }
  }

  &-active {
    z-index: 100;
  }

  &-enabled {
    opacity: 1;
  }

  &-icon {
    $icon-width: 28px;
    $icon-height: 28px;

    margin-bottom: 14px;
    width: $icon-width;
    height: $icon-height;
    opacity: 0;
    transition: opacity ($transition-duration * 3) ease-out 250ms;
    fill: $white;

    @include breakpoint(map-get($breakpoints, medium)) {
      $icon-width: 32px;
      $icon-height: 32px;

      width: $icon-width;
      height: $icon-height;
    }

    .not-displayable-enabled & {
      opacity: 1;
    }
  }

  &-text {
    @extend %section-horizontal-padding;

    margin: 0 auto;
    width: 100%;
    text-align: center;
    opacity: 0;
    transform: scale(0);

    @include transitions(
      ($transition-duration * 2),
      ease-out,
      $transition-duration,
      (transform, opacity)
    );

    .not-displayable-enabled & {
      opacity: 1;
      transform: scale(1);
    }
  }
}
