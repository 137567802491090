//
// CSSGuy4Hire - fonts
//

// Configuration
@import 'config/variables';

//
// helpers
//
@import 'helpers/functions';

//
// fonts
//
@each $font in map_keys($fonts) {
  @each $font-weight in map_keys(map-get($fonts, $font)) {
    $fonts-dir: '../../assets/fonts/';
    $font-name: map-deep-get($fonts, $font, $font-weight);

    @font-face {
      font-family: '#{$font}';
      src: url('#{$fonts-dir}#{$font-name}.woff2') format('woff2'),
        url('#{$fonts-dir}#{$font-name}.woff') format('woff');
      font-weight: $font-weight;
      font-style: normal;
      font-display: block;
    }
  }
}
