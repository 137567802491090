$env-production: true;
@import '~@styles/config/variables';

.block {
  margin-bottom: 15px;
  padding: 15px 15px 30px;
  background-color: $white;
  box-shadow: 1px 1px 10px $dark-grey;

  &:last-child {
    margin-bottom: 15px; // overrides default
  }

  &-heading {
    margin: 0 0 8px;
    padding: 0 24px;
    letter-spacing: 8px;
    line-height: 1.2;
    text-transform: uppercase;
    position: relative;
    z-index: 0;

    &:after,
    &:before {
      content: '';
      top: 50%;
      left: 0;
      width: 100%;
      height: 1px;
      display: block;
      position: absolute;
      z-index: -1;
      background-color: $dark-grey;
    }

    &-text {
      padding: 0 8px;
      display: inline-block;
      background-color: $light-grey;
    }
  }

  &-inner {
    margin-bottom: 36px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
