$env-production: true;
@import '~@styles/config/variables';

.panels {
  width: 100%;
  height: 100%;
}

:global {
  .swiper-wrapper {
    > div {
      &:first-child,
      &:last-child {
        $shadow-height: 20px;
        $shadow-colors: rgba($black, 0.85) 1%, rgba($black, 0.55) 25%,
          rgba($black, 0) 60%;

        &:before,
        &:after {
          content: '';
          top: -$shadow-height;
          left: 0;
          width: 100%;
          height: $shadow-height;
          display: block;
          position: absolute;
          background-image: linear-gradient(0deg, $shadow-colors);
        }

        &:after {
          top: auto;
          bottom: -$shadow-height;
          background-image: linear-gradient(180deg, $shadow-colors);
        }
      }

      &:first-child {
        &:after {
          display: none;
        }
      }

      &:last-child {
        &:before {
          display: none;
        }
      }
    }
  }
}

.panel {
  margin: 0;
  padding: 0;

  width: 100%;
  height: 100%;
  min-height: 100%;
  display: flex;

  justify-content: center;
  align-items: center;
  position: relative;
}
