//
// helpers
//

html, body, * {
  box-sizing: border-box;

  &:before,
  &:after {
      box-sizing: inherit;
  }
}

// hidden (visually)
.hidden {
  @include hidden;
}
