//
// functions
//

// Libraries Mixins
@import '~breakpoint-sass/stylesheets/breakpoint';

// mediaQueries info
// get on screen information for debugging media queries
@mixin queryInfo($breakpoints: $breakpoints) {
  @if $debug == true and $env-production == false {
    &:after {
      right: 0;
      bottom: 0;
      padding: 0.2rem;
      color: #fff;
      display: block;
      position: fixed;
      z-index: 200;
      background-color: #000000;
      @each $breakpoint in map_keys($breakpoints) {
        @include breakpoint(map-get($breakpoints, $breakpoint)) {
          content: '#{$breakpoint} - #{map-get($breakpoints, $breakpoint)}';
          @include breakpoint((orientation landscape)) {
            content: '#{$breakpoint} - #{map-get($breakpoints, $breakpoint)} - landscape';
          }
        }
      }
    }
  }
}

// hidden extend wrapper
@mixin hidden($extend: true) {
  @if $extend {
    @extend %hidden;
  } @else {
    width: 0;
    height: 0;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
}

// hidden placeholder
%hidden {
  @include hidden($extend: false);
}

// clear fix
@mixin clearFix($extend: true) {
  @if $extend {
    @extend %clearFix;
  } @else {
    &:after {
      content: ' ';
      height: 0;
      display: block;
      clear: both;
      visibility: hidden;
      overflow: hidden;
    }
  }
}

// clear fix placeholder
%clearFix {
  @include clearFix($extend: false);
}

// remove text
@mixin remove-text($extend: true) {
  @if $extend {
    @extend %remove-text;
  } @else {
    font-size: 0;
    line-height: 0;
    text-indent: 100%;
    overflow: hidden;
  }
}

// remove text placeholder
%remove-text {
  @include remove-text($extend: false);
}

// no highlight
@mixin no-highlight($extend: true) {
  @if $extend {
    @extend %no-highlight;
  } @else {
    outline: 0;
    outline-style: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
}

// remove text placeholder
%no-highlight {
  @include no-highlight($extend: false);
}

// transitions
@mixin transitions($duration, $timingFunction, $delay, $properties: all) {
  $propsAndTime: ();

  @each $prop in $properties {
    $prop: $prop;
    $propsAndTime: append(
      $propsAndTime,
      ($prop $duration $timingFunction $delay),
      comma
    );
  }

  transition: $propsAndTime;
}
