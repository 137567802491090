$env-production: true;
@import '~@styles/config/variables-export';

.grid {
  &-item {
    &-color {
      width: 110px;
    }
  }

  &-thumb {
    &-color {
      padding: 0 0 100%;
      width: 100%;
      height: 0;
    }
  }
}
