$env-production: true;
@import '~@styles/config/variables';
@import '~@styles/config/variables-export';
@import '~@styles/helpers/mixins';
@import '~@styles/helpers/functions';

$image-init-scale: 1.0001;
$images-fade-duration: 500ms;
$frame-shadow-duration: 500ms;
$frame-shadow-delay: $images-fade-duration - 300ms;
$image-fade-in-duration: 1200ms;
$image-fade-in-delay: $frame-shadow-delay;
$images-blur-duration: 700ms;
$images-blur-delay: $image-fade-in-duration + $image-fade-in-delay;

.work-sample {
  display: flex;
  flex-direction: column;

  @include breakpoint((orientation landscape)) {
    display: grid;
    grid-template-areas:
      'image title'
      'image description';
    grid-template-columns: 25% 1fr;
    column-gap: 5%;
  }

  &-title {
    h4 {
      line-height: 1;
    }

    h5 {
      margin-top: 3.5px;
      font-family: $default-font;
      font-size: $font-size-xxx-small - 1;
      letter-spacing: 0.015em;
      color: $white;
      opacity: 0.7;

      @include breakpoint(map-get($breakpoints, medium)) {
        margin-top: 4.5px;
        font-size: $font-size-xx-small;
      }

      @include breakpoint(map-get($breakpoints, large)) {
        margin-top: 6px;
        font-size: $font-size-small;
      }

      &:after {
        content: '';
        margin: 6px 0 7px;
        width: 10%;
        height: 1px;
        display: block;
        opacity: 0.5;
        background-color: $white;

        @include breakpoint(map-get($breakpoints, medium)) {
          margin: 8px 0 10px;
          height: 2px;
        }

        @include breakpoint(map-get($breakpoints, large)) {
          margin: 10px 0 12px;
          height: 3px;
        }
      }
    }

    @include breakpoint((orientation landscape)) {
      grid-area: title;
    }
  }

  &-image {
    padding: 8px 0 16px;
    max-width: 50%;
    line-height: 0;
    order: 0;

    @include breakpoint((orientation landscape)) {
      max-width: unset;
      grid-area: image;
    }
  }

  &-description {
    b,
    strong {
      color: $orange-peel;
    }

    @include breakpoint((orientation landscape)) {
      grid-area: description;
    }
  }
}

.images {
  margin: 0;
  opacity: 0.15;
  position: relative;
  filter: brightness(0%);

  svg {
    width: 100%;
    height: auto;
    fill: darken($white, 10%);
    filter: drop-shadow(0 0 0 rgba($black, 0));
  }

  @include transitions($images-fade-duration, ease-out, 0ms, (filter, opacity));

  @at-root :global .slide-active :local & {
    opacity: 1;
    filter: brightness(100%);

    svg {
      filter: drop-shadow(3px 3px 2px rgba($black, 0.2));
    }
  }
}
